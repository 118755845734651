import axios from "axios"
import { useState } from "react"
import { FaEdit, FaRegSave } from "react-icons/fa"
import { FcCancel } from "react-icons/fc"

const AvailableDesignsPopup = ({ images, fetchImages }) => {
	const accessString = localStorage.getItem("JWT")

	const [editingImageId, setEditingImageId] = useState(null)
	const [editedLinkUrl, setEditedLinkUrl] = useState("")

	const setAsPublic = async (id) => {
		try {
			await axios.put(
				`/homepopup/public/${id}`,
				{},
				{
					headers: { jwt: `${accessString}` },
				}
			)
			fetchImages()
		} catch (error) {
			console.error("Error setting image as public:", error)
			alert("Failed to set the image as public.")
		}
	}

	const deleteImage = async (id) => {
		if (!window.confirm("Are you sure you want to delete this design?")) return

		try {
			await axios.delete(`/homepopup/delete/${id}`, {
				headers: { jwt: `${accessString}` },
			})
			alert("Image deleted successfully!")
			fetchImages()
		} catch (error) {
			console.error("Error deleting image:", error)
			alert("Failed to delete the image.")
		}
	}

	const toggleVisibility = async (id, currentVisibility) => {
		try {
			await axios.put(
				`/homepopup/update/${id}`,
				{
					isVisible: !currentVisibility,
				},
				{
					headers: { jwt: `${accessString}` },
				}
			)
			alert("Visibility updated successfully!")
			fetchImages()
		} catch (error) {
			console.error("Error updating visibility:", error)
			alert("Failed to update visibility.")
		}
	}

	const handleEditLinkUrl = (id, linkUrl) => {
		setEditingImageId(id)
		setEditedLinkUrl(linkUrl)
	}

	const saveLinkUrl = async (id) => {
		try {
			await axios.put(
				`/homepopup/updateLinkUrl/${id}`,
				{ linkUrl: editedLinkUrl },
				{
					headers: { jwt: `${accessString}` },
				}
			)
			setEditingImageId(null)
			fetchImages()
		} catch (error) {
			console.error("Error saving the new path:", error)
			alert("Failed to save the new path.")
		}
	}

	return (
		<div className="mb-6">
			<h2 className="text-xl font-semibold mb-4">
				Available Designs for Home page Popup
			</h2>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
				{images.map((image) => (
					<div key={image.id} className="p-4 bg-gray-100 rounded-lg shadow-md">
						<img
							src={image.imgUrl}
							alt="Uploaded"
							className="w-full h-32 object-cover rounded-md"
						/>
						<p className="mt-2 text-sm">
							Visibility:{" "}
							<span
								className={`font-semibold ${
									image.isVisible ? "text-green-600" : "text-red-600"
								}`}
							>
								{image.isVisible ? "Public" : "Private"}
							</span>
						</p>

						{/* Editable Link URL */}
						<p className="mt-2 text-sm">
							Path:{" "}
							{editingImageId === image.id ? (
								<div className="flex gap-1 items-center">
									<input
										type="text"
										value={editedLinkUrl}
										onChange={(e) => setEditedLinkUrl(e.target.value)}
										className="border w-3/4 rounded-md p-1"
									/>
									{editingImageId === image.id && (
										<>
											<FaRegSave
												size={20}
												onClick={() => saveLinkUrl(image.id)}
												className="cursor-pointer text-sky-500 pb-1"
											/>
											<FcCancel
												onClick={() => setEditingImageId(null)}
												size={20}
												className="cursor-pointer pb-1"
											/>
										</>
									)}
								</div>
							) : (
								<>
									<span className="font-semibold">{image.linkUrl}</span>
									{editingImageId !== image.id && (
										<FaEdit
											onClick={() => handleEditLinkUrl(image.id, image.linkUrl)}
											className="inline cursor-pointer text-cyan-500 ml-2 pb-1 text-lg"
										/>
									)}
								</>
							)}
						</p>

						<div className="mt-2 flex space-x-2">
							{!image.isVisible && (
								<button
									onClick={() => deleteImage(image.id)}
									className="py-2 px-4 text-white bg-red-600 hover:bg-red-700 font-semibold rounded-md"
								>
									Delete
								</button>
							)}
							{image.isVisible && (
								<button
									onClick={() => toggleVisibility(image.id, image.isVisible)}
									className={`py-1 px-4 text-white font-semibold rounded-md ${
										image.isVisible
											? "bg-red-600 hover:bg-red-700"
											: "bg-blue-600 hover:bg-blue-700"
									}`}
								>
									{image.isVisible ? "Set to Private" : "Set to Public"}
								</button>
							)}

							<button
								onClick={() => setAsPublic(image.id)}
								disabled={image.isVisible}
								className={`py-1 px-4 text-white font-semibold rounded-md ${
									image.isVisible
										? "bg-gray-300 cursor-not-allowed"
										: "bg-blue-600 hover:bg-blue-700"
								}`}
							>
								{image.isVisible ? "Public" : "Set as Public"}
							</button>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default AvailableDesignsPopup
