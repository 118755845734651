import "react-app-polyfill/ie9"
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from "react"
import ReactDOM from "react-dom"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import App from "./App"
import registerServiceWorker from "./registerServiceWorker"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import "bootstrap/dist/css/bootstrap.min.css"

ReactDOM.render(
	<DndProvider backend={HTML5Backend}>
		<App />
	</DndProvider>,
	document.getElementById("root")
)
registerServiceWorker()
