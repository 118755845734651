import axios from "axios"
import moment from "moment"
import React, { useState } from "react"

const GiftCardValidator = ({
	validateGiftCard,
	giftCardCode,
	giftCard,
	setGiftCardCode,
	setIsCodeValid,
	giftCardAmountToUse,
	setGiftCardAmountToUse,
	commissionId,
	getAllCommissions,
	toggleModalToEditCommission,
	isClient = false,
}) => {
	const [validationMessage, setValidationMessage] = useState("")
	const [isLoading, setIsLoading] = useState(false)

	const handleValidation = async (e) => {
		e.preventDefault()
		if (!giftCardCode || giftCardCode.length < 8) {
			setValidationMessage(
				"Please enter at least the last 8 characters of the code."
			)
			return
		}

		setIsLoading(true)
		setValidationMessage("")

		try {
			const isValid = await validateGiftCard(giftCardCode)
			if (isValid) {
				setValidationMessage("🎉 Gift card is valid!")
				setIsCodeValid(true)
			} else {
				setValidationMessage(
					"❌ Gift card code is not Valid. Please try again."
				)
				setIsCodeValid(false)
			}
		} catch (error) {
			setIsCodeValid(false)
			setValidationMessage(
				"⚠️ Error validating gift card. Please try again later."
			)
		} finally {
			setIsLoading(false)
		}
	}

	const useGiftCardBalanceHandler = async (id, e) => {
		e.preventDefault()
		const currentBalance = giftCard.balance

		if (
			parseFloat(giftCardAmountToUse).toFixed(2) > parseFloat(currentBalance)
		) {
			return alert(
				"The amount you intend to use must be less than the available balance in card"
			)
		}
		const giftCardObj = {
			balance: currentBalance - giftCardAmountToUse,
			amountUsedFromCard: giftCardAmountToUse,
			commissionId,
		}
		await axios.put(`/api/gift_card/update/${id}`, giftCardObj)
		getAllCommissions()
		toggleModalToEditCommission()
	}

	return (
		<div className="max-w-md mx-auto p-6 border rounded-lg shadow-md bg-white">
			<h3 className="text-lg font-semibold text-gray-800 mb-1 text-center">
				Gift Card Validation
			</h3>
			<div className="">
				<label
					htmlFor="giftCardInput"
					className="block text-gray-600 font-medium"
				>
					Enter your gift card code:
				</label>
				<input
					id="giftCardInput"
					type="text"
					value={giftCardCode}
					onChange={(e) => setGiftCardCode(e.target.value)}
					placeholder="e.g., 49C2C398"
					className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-500 focus:outline-none"
					maxLength={20}
				/>
				<button
					onClick={handleValidation}
					disabled={isLoading || !giftCardCode}
					className={`w-full px-4 py-2 rounded-md text-white ${
						isLoading
							? "bg-gray-400 cursor-not-allowed"
							: "bg-green-500 hover:bg-green-600"
					}`}
				>
					{isLoading ? "Validating..." : "Validate Gift Card"}
				</button>
				{/* Display Validation Message */}
				{validationMessage && (
					<p
						className={`mt-2 text-center font-semibold ${
							validationMessage.includes("valid")
								? "text-green-500"
								: "text-red-500"
						}`}
					>
						{validationMessage}
					</p>
				)}
			</div>
			{giftCard && (
				<div className="p-2 border-dashed border-2 border-yellow-500">
					<span className="text-slate-500">
						Purchased Date: {moment(giftCard.createdAt).format("YYYY-MM-DD")}
					</span>
					<br />
					<span className="text-slate-500">Code: {giftCard.code}</span>
					<br />
					<span className="text-slate-500">
						Original Value: ${giftCard.originalBalance}
					</span>
					<br />
					<span>Available Funds: ${giftCard.balance}</span> <br />
					{isClient && giftCard.balance > 0 && (
						<span className="text-sm">
							When it's time to pay, just provide the code to our staff
						</span>
					)}
					{!isClient && (
						<div>
							<hr />
							<label>Amount to use for payment:</label>
							<input
								placeholder="Type amount for payment"
								className="w-full p-2 border rounded-sm"
								type="number"
								min={0}
								value={giftCardAmountToUse}
								onChange={(e) => setGiftCardAmountToUse(e.target.value)}
							/>
							{giftCardAmountToUse && (
								<button
									onClick={(e) => useGiftCardBalanceHandler(giftCard.id, e)}
									className="p-2 w-full mt-2 bg-cyan-600 text-white rounded-sm"
								>
									Submit Gift Card balance
								</button>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	)
}

export default GiftCardValidator
