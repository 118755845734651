import React, { useState, useEffect } from "react"
import { useTable } from "react-table"
import { saveAs } from "file-saver"
import Papa from "papaparse"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"

// Tailwind CSS styles
const tableStyles = `
  table-auto w-full border-collapse border border-gray-300
  text-sm text-gray-700 bg-white rounded-md shadow-md
`

const Table = () => {
	const [logs, setLogs] = useState([])
	const [isFetching, setIsFetching] = useState(false)
	const [filters, setFilters] = useState({})
	const [startDate, setStartDate] = useState("")
	const [endDate, setEndDate] = useState("")
	const [hasNextPage, setHasNextPage] = useState(true)
	const [page, setPage] = useState(0)
	const [recordsPerPage, setRecordsPerPage] = useState(10)
	const [totalRecords, setTotalRecords] = useState(0)
	const accessString = localStorage.getItem("JWT")

	const notify = (msgContent) => toast.success(`${msgContent}`)

	const fetchLogs = async (forExport = false) => {
		setIsFetching(true)
		try {
			const filterParams = {
				...filters,
				startDate,
				endDate,
				page: forExport ? 0 : page,
				pageSize: forExport ? 100000 : recordsPerPage,
			}

			const { data } = await axios.get("/schedule/logs/all", {
				params: filterParams,
				headers: { jwt: `${accessString}` },
			})
			if (forExport) {
				return
			}

			setLogs(data.logs)
			setHasNextPage(data.hasNextPage)
			setTotalRecords(data.totalRecords)
		} catch (error) {
			console.error("Error fetching logs:", error)
		} finally {
			setIsFetching(false)
		}
	}

	useEffect(() => {
		setLogs([])
		fetchLogs()
	}, [filters, page, recordsPerPage])

	const columns = React.useMemo(
		() => [
			{ Header: "ID", accessor: "id" },
			{ Header: "Action", accessor: "action" },
			{ Header: "Created At", accessor: "createdAt" },
			{ Header: "Event ID", accessor: "eventId" },
			{
				Header: "Event Summary",
				accessor: (row) => JSON.stringify(row.eventSummary, null, 2),
			},
			{
				Header: "Actions",
				Cell: ({ row }) => (
					<button
						className="px-3 py-1 bg-red-500 text-white rounded"
						onClick={() => handleDeleteLog(row.original.id)}
					>
						Delete
					</button>
				),
			},
		],
		[]
	)

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({ columns, data: logs })

	const handleDeleteLog = async (id) => {
		if (
			window.confirm(
				"Are you sure you want to delete this log? This action is permanent and not reversible."
			)
		) {
			try {
				await axios.delete(`/schedule/logs/${id}`, {
					headers: { jwt: `${accessString}` },
				})

				const newPage = Math.min(
					page,
					Math.ceil((totalRecords - 1) / recordsPerPage) - 1
				)
				setPage(newPage)
				fetchLogs()
			} catch (error) {
				console.error("Error deleting log:", error)
			}
		}
	}

	const handleDeleteFiltered = async () => {
		if (!startDate || !endDate) {
			return alert("Filter the logs by date first!")
		}

		if (
			window.confirm(
				"Are you sure you want to delete these logs? This action is permanent and not reversible."
			)
		) {
			try {
				const response = await axios.post(
					"/schedule/logs/deleteFiltered",
					{ filters },
					{
						headers: { jwt: `${accessString}` },
					}
				)
				notify(response.data.message)

				setLogs([])
				setPage(0)
				setStartDate("")
				setEndDate("")
				setFilters({})
			} catch (error) {
				console.error("Error deleting filtered logs:", error)
			}
		}
	}

	const handleExportCSV = async () => {
		setIsFetching(true)
		try {
			const { data } = await axios.get("/schedule/logs/all", {
				params: {
					startDate,
					endDate,
					page: 0,
					pageSize: 1000000,
					isExport: true,
				},
				headers: { jwt: `${accessString}` },
			})

			const logsToExport = data.logs.map((log) => ({
				...log,
				eventSummary: JSON.stringify(log.eventSummary),
			}))

			const csv = Papa.unparse(logsToExport)
			const blob = new Blob([csv], { type: "text/csv" })
			saveAs(blob, "logs.csv")
		} catch (error) {
			console.error("Error exporting logs:", error)
		} finally {
			setIsFetching(false)
		}
	}

	return (
		<div className="p-4">
			<ToastContainer
				position="top-right"
				autoClose={7000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<h1 className="text-2xl font-bold mb-4">Logs Table</h1>
			<div className="flex flex-wrap justify-between items-center space-x-4 mb-4">
				<div className="flex flex-wrap items-center space-x-4 mb-4 md:mb-0">
					<div>
						<label className="block text-sm">Start Date</label>
						<input
							type="date"
							className="border rounded px-2 py-1"
							value={startDate}
							onChange={(e) => setStartDate(e.target.value)}
						/>
					</div>
					<div>
						<label className="block text-sm">End Date</label>
						<input
							type="date"
							className="border rounded px-2 py-1"
							value={endDate}
							onChange={(e) => setEndDate(e.target.value)}
						/>
					</div>
				</div>
				<div className="flex flex-wrap space-x-4">
					<button
						className="px-4 py-2 bg-blue-500 text-white rounded mb-2 md:mb-0"
						onClick={() => setFilters({ startDate, endDate })}
					>
						Apply Filters
					</button>
					<button
						className="px-4 py-2 bg-red-500 text-white rounded mb-2 md:mb-0"
						onClick={handleDeleteFiltered}
					>
						Delete Filtered Logs
					</button>
					<button
						className="px-4 py-2 bg-green-500 text-white rounded mb-2 md:mb-0"
						onClick={handleExportCSV}
					>
						Export CSV
					</button>
				</div>
			</div>

			<div className="overflow-auto border rounded" style={{ height: "400px" }}>
				<table {...getTableProps()} className={tableStyles}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps()} className="border p-2">
										{column.render("Header")}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row) => {
							prepareRow(row)
							return (
								<tr {...row.getRowProps()} className="border">
									{row.cells.map((cell) => (
										<td {...cell.getCellProps()} className="p-2">
											{cell.render("Cell")}
										</td>
									))}
								</tr>
							)
						})}
					</tbody>
				</table>
				{isFetching && <p className="text-center p-2">Loading more...</p>}
			</div>

			{/* Pagination Controls */}
			<div className="flex justify-between items-center mt-4">
				<button
					className={`px-4 py-2 ${
						page === 0 ? "bg-slate-400" : "bg-slate-500"
					} text-white rounded`}
					onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
					disabled={page === 0}
				>
					Previous
				</button>
				<span>
					Page {page + 1} of {Math.ceil(totalRecords / recordsPerPage)} - Total
					Records: {totalRecords}
				</span>
				<button
					className={`px-4 py-2 ${
						!hasNextPage || isFetching ? "bg-slate-400" : "bg-slate-500"
					}  text-white rounded`}
					onClick={() => setPage((prevPage) => prevPage + 1)}
					disabled={!hasNextPage || isFetching}
				>
					Next
				</button>
			</div>

			{/* Records per page selector */}
			<div className="mt-4">
				<label className="block text-sm mb-2">Records per page:</label>
				<select
					className="border rounded px-2 py-1"
					value={recordsPerPage}
					onChange={(e) => {
						setRecordsPerPage(parseInt(e.target.value))
						setPage(0)
					}}
				>
					<option value="10">10</option>
					<option value="20">20</option>
					<option value="50">50</option>
					<option value="100">100</option>
				</select>
			</div>
		</div>
	)
}

export default Table
