import React from "react"
import BookingCard from "../BookingCard"

const DogServices = ({
	onClickGrooming,
	onClickBath,
	onClickGroomingExpress,
	onClickBathExpress,
}) => {
	return (
		<>
			<h5 className="text-black font-black text-[24px] md:text-[45px] text-center mb-6">
				Choose type of service:
			</h5>

			<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-[30px]">
				<BookingCard
					extraImg="/images/express-icon.png"
					image="/images/grooming-icon.png"
					title="Grooming Express"
					description="Haircut, bath, brush, nails, ears."
					onClick={onClickGroomingExpress}
				/>
				<BookingCard
					image="/images/grooming-icon.png"
					title="Grooming Standard"
					description="Haircut, bath, brush, nails, ears."
					onClick={onClickGrooming}
				/>
				<BookingCard
					extraImg="/images/express-icon.png"
					image="/images/bath-icon.png"
					title="Bath Express"
					description="Bath, brush, nails, ears."
					onClick={onClickBathExpress}
				/>
				<BookingCard
					image="/images/bath-icon.png"
					title="Bath Standard"
					description="Bath, brush, nails, ears."
					onClick={onClickBath}
				/>
			</div>
		</>
	)
}

export default DogServices
