import React, { useState } from "react"

const ImageUploader = ({
	uploading,
	handleUpload,
	setImage,
	preview,
	setPreview,
}) => {
	const handleImageChange = (e) => {
		const file = e.target.files[0]
		if (file) {
			setImage(file)
			setPreview(URL.createObjectURL(file))
		}
	}

	return (
		<div className="flex flex-col items-center p-4 bg-gray-100">
			<h2 className="text-xl font-bold mb-4">New Home Popup Design Upload</h2>

			<div className="w-full max-w-md p-4 bg-white rounded-lg shadow-md">
				<div className="flex flex-col items-center">
					{preview ? (
						<img
							src={preview}
							alt="Preview"
							className="w-32 h-32 object-cover rounded-full mb-4"
						/>
					) : (
						<div className="w-32 h-32 bg-gray-200 rounded-full flex items-center justify-center mb-4">
							<span className="text-gray-500">No Image</span>
						</div>
					)}
					<input
						type="file"
						accept="image/*"
						className="block w-full text-sm text-gray-500
                            file:mr-4 file:py-2 file:px-4
                            file:rounded-full file:border-0
                            file:text-sm file:font-semibold
                            file:bg-blue-50 file:text-blue-700
                            hover:file:bg-indigo-100 mb-4"
						onChange={handleImageChange}
					/>
				</div>

				<button
					onClick={handleUpload}
					disabled={uploading}
					className={`w-full py-2 px-4 text-white font-semibold rounded-md ${
						uploading
							? "bg-indigo-300 cursor-not-allowed"
							: "bg-blue-600 hover:bg-blue-700"
					}`}
				>
					{uploading ? "Uploading and Saving..." : "Upload & Save"}
				</button>
			</div>
		</div>
	)
}

export default ImageUploader
