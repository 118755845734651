import { useState, useEffect } from "react"
import ImageUploader from "../ImageUploader"
import axios from "axios"
import AvailableDesignsPopup from "../AvailableDesignsPopup"

export default function Index() {
	const [uploading, setUploading] = useState(false)
	const [image, setImage] = useState(null)
	const [linkUrl, setLinkUrl] = useState(null)
	const [visibility, setVisibility] = useState("public")
	const [images, setImages] = useState([])
	const [preview, setPreview] = useState(null)
	const accessString = localStorage.getItem("JWT")

	useEffect(() => {
		fetchImages()
	}, [])

	const fetchImages = async () => {
		setPreview(null)
		try {
			const response = await axios.get("/homepopup/setting")
			setImages(response.data)
		} catch (error) {
			console.error("Error fetching images:", error)
		}
	}

	const handleUploadAndSave = async () => {
		if (!image) {
			alert("Please select an image first.")
			return
		}

		setUploading(true)
		const formData = new FormData()
		formData.append("file", image)
		formData.append("upload_preset", "amazing")

		try {
			const uploadResponse = await axios.post(
				"https://api.cloudinary.com/v1_1/amazing-pet-grooming/image/upload",
				formData
			)
			const uploadedUrl = uploadResponse.data.secure_url
			await axios.post(
				"/homepopup/new",
				{
					imgUrl: uploadedUrl,
					isVisible: visibility === "public" ? true : false,
					linkUrl,
				},
				{
					headers: { jwt: `${accessString}` },
				}
			)

			fetchImages()
			setImage(null)
		} catch (error) {
			console.error("Error uploading or saving:", error)
			alert("Failed to upload or save.")
		} finally {
			setUploading(false)
		}
	}

	return (
		<div className="p-4 bg-gray-50 min-h-screen">
			<div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6">
				<AvailableDesignsPopup fetchImages={fetchImages} images={images} />
				<hr />
				<ImageUploader
					uploading={uploading}
					handleUpload={handleUploadAndSave}
					setImage={setImage}
					preview={preview}
					setPreview={setPreview}
				/>

				<div className="text-center w-full">
					<input
						onChange={(e) => setLinkUrl(e.target.value)}
						className="border rounded-md p-1 w-3/4"
						type="text"
						placeholder="Path Url, eg: store, services, daycare, gallery, "
					/>
				</div>

				<div className="mt-4">
					<h2 className="text-lg font-semibold mb-2">Set Popup Visibility</h2>
					<div className="flex items-center space-x-4">
						<label className="flex items-center space-x-2">
							<input
								type="radio"
								value="public"
								name="visibility"
								checked={visibility === "public"}
								onChange={(e) => setVisibility(e.target.value)}
								className="text-indigo-600 focus:ring-indigo-500"
							/>
							<span>Public</span>
						</label>
						<label className="flex items-center space-x-2">
							<input
								type="radio"
								value="private"
								name="visibility"
								checked={visibility === "private"}
								onChange={(e) => setVisibility(e.target.value)}
								className="text-indigo-600 focus:ring-indigo-500"
							/>
							<span>Private</span>
						</label>
					</div>
				</div>
			</div>
		</div>
	)
}
