import React, { useState, useEffect } from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import EmployeeSignupForm from "../../components/EmployeesForms/EmployeeSignup"
import { Table } from "reactstrap"
import LoadPage from "../../components/LoadingPage"
import ModalToEditEmployee from "./ModalToEditEmployee"
import SpecialCalculations from "./SpecialCalculations"
import "./style.scss"
import ModalForCommissions from "./ModalForCommissions"
import DatePicker from "react-datepicker"
import moment from "moment"
import Select from "react-select"
import { FaExternalLinkAlt } from "react-icons/fa"
import ClientsList from "./ClientsList"
import { jwtDecode } from "jwt-decode"
import DaycarePassesList from "./DaycarePassesList"
import ModalGeneralPurpose from "../../components/Modals/ModalGeneralPurpose"
import DiscountForm from "../../components/DiscountForm"
import DiscountList from "../../components/DiscountList"
import Logs from "../../components/Logs"
import HomePopupSettings from "../../components/HomePopupSettings"
import SessionsTable from "./SessionsTable"
import { FaRegEye } from "react-icons/fa"
import { BsPlusSquareFill } from "react-icons/bs"

const Index = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(false)
	const [toggleEmployeeForm, setToggleEmployeeForm] = useState(false)
	const [toggleCommissionAdmin, setToggleCommissionAdmin] = useState(true)
	const [employees, setEmployees] = useState([])
	const [allEmployeesByCommission, setAllEmployeesByCommission] = useState([])
	const [showClientsList, setShowClientsList] = useState(false)
	const [showDaycarePassesList, setShowDaycarepassesList] = useState(false)
	const [showDiscountButtons, setShowDiscountButtons] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isModalToViewOpen, setIsModalToViewOpen] = useState(false)
	const [modalForSpecialCalculations, setModalForSpecialCalculations] =
		useState(false)
	const accessString = localStorage.getItem("JWT")
	const userRole = localStorage.getItem("JOBTYPE")
	const [loadingAxiosReq, setLoadingAxiosReq] = useState(false)
	const [commissionHandlerOpenModal, setCommissionHandlerOpenModal] =
		useState(false)
	const [commissionSearch, setCommissionSearch] = useState(false)
	const [isModalForLogsOpen, setIsModalForLogsOpen] = useState(false)
	const [isModalForUsersSessionsOpen, setIsModalForUsersSessionsOpen] =
		useState(false)
	const [isModalForHomePopupSettingsOpen, setIsModalForHomePopupSettingsOpen] =
		useState(false)
	const [modalToEditEmployee, setModalToEditEmployee] = useState(false)
	const [employeeId, setEmployeeId] = useState("")
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [username, setUsername] = useState("")
	const [email, setEmail] = useState("")
	const [location, setLocation] = useState("")
	const [wage, setWage] = useState("")
	const [dateOfBirth, setDateOfBirth] = useState(null)
	const [firstDayOfWork, setFirstDayOfWork] = useState("")
	const [sickDaysAvailable, setSickDaysAvailable] = useState("")
	const [daysOffAvailable, setDaysOffAvailable] = useState("")
	const [vacationDaysAvailable, setVacationDaysAvailable] = useState("")
	const [jobType, setJobType] = useState("")
	const [errMsg, setErrorMsg] = useState("")
	const [searchDateStart, setSearchDateStart] = useState("")
	const [searchDateEnd, setSearchDateEnd] = useState("")
	const [employeeName, setEmployeeName] = useState("")
	const [commissionModalMode, setCommissionModalMode] = useState("")
	const [salarySearchResults, setSalarySearchResults] = useState([])
	const [searchResultMsg, setSearchResultMsg] = useState(
		"Enter dates and employee name above"
	)
	const [salaryIdForUpdate, setSalaryIdForUpdate] = useState("")
	const [employeePassword, setEmployeePassword] = useState("")
	const [employeePassword2, setEmployeePassword2] = useState("")
	const [accountStatus, setAccountStatus] = useState("")
	const [limitedAccessToCambridgeAdmin, setLimitedAccessToCambridgeAdmin] =
		useState("")

	useEffect(() => {
		if (localStorage.getItem("JWT")) {
			fetchData()
			getAllEmployeesByCommission()
		} else {
			localStorage.clear()
			window.location.href = "/"
		}
	}, [])

	useEffect(() => {
		if (!modalToEditEmployee) {
			setEmployeeId("")
			setFirstName("")
			setLastName("")
			setUsername("")
			setEmail("")
			setLocation("")
			setWage("")
			setJobType("")
			setDateOfBirth("")
			setFirstDayOfWork("")
			setSickDaysAvailable("")
			setDaysOffAvailable("")
			setVacationDaysAvailable("")
			setErrorMsg("")
			setEmployeePassword("")
			setEmployeePassword2("")
			setAccountStatus("")
			setLimitedAccessToCambridgeAdmin("")
		}
	}, [modalToEditEmployee])

	const searchCommissionHandler = async (e) => {
		e.preventDefault()
		if (!searchDateStart || !searchDateEnd || !employeeName) {
			return alert("Both dates and employee Name are required!")
		}
		refreshSearchResults()
	}

	const refreshSearchResults = async () => {
		const startDate = moment(searchDateStart).format("YYYY-MM-DD")
		const endDate = moment(searchDateEnd).format("YYYY-MM-DD")
		await axios
			.get(`/auth/search/allEmployees/${startDate}/${endDate}/${employeeName}`)
			.then((res) => {
				if (!res.data.length) {
					setSearchResultMsg("No data found! Try a different search.")
				}
				setSalarySearchResults(res.data)
			})
			.catch((err) => console.log(err))
	}

	const handleDeleteCommission = async (id) => {
		if (
			window.confirm(
				`Are you sure you wish to delete this commission permanently?`
			)
		) {
			await axios
				.delete("/auth/delete/salary/" + id)
				.then(async () => {
					refreshSearchResults()
				})
				.catch((err) => console.log(err))
			// props.history.push("/auth/reception");
		}
	}

	const fetchData = async () => {
		try {
			const token = localStorage.getItem("JWT")

			const decodedToken = jwtDecode(token)
			if (decodedToken.exp * 1000 < Date.now()) {
				window.location.href = "/"
				return
			}

			const response = await axios.get(`/auth/admin`, {
				headers: { Authorization: `JWT ${accessString}` },
			})

			if (response.data.jobType === "admin") {
				setIsLoading(false)
				setError(false)
				setErrorMsg("")
			} else {
				setErrorMsg("Unauthorized")
			}
		} catch (error) {
			console.log(error.response)
			if (error.response && error.response.status === 401) {
				setError("Unauthorized access")
			} else {
				setError("An error occurred")
			}
		}
	}

	const getAllEmployees = async () => {
		setLoadingAxiosReq(true)
		await axios
			.get("/auth/employees", {
				headers: { Authorization: `JWT ${accessString}` },
			})
			.then((res) => {
				setLoadingAxiosReq(false)
				let onlyEmployees = res.data.filter((user) => {
					return user.jobType !== "admin"
				})
				setEmployees(onlyEmployees)
			})
			.catch((err) => {
				setLoadingAxiosReq(false)
				console.log(err)
			})
	}

	const getEmployeeInfo = async ({ currentTarget }) => {
		const id = currentTarget.value
		setModalToEditEmployee(!modalToEditEmployee)
		await axios
			.get("/auth/employees/" + id, {
				headers: { Authorization: `JWT ${accessString}` },
			})
			.then((res) => {
				setEmployeeId(res.data.id)
				setFirstName(res.data.firstName)
				setLastName(res.data.lastName)
				setUsername(res.data.username)
				setEmail(res.data.email)
				setLocation(res.data.location)
				setWage(res.data.wage)
				setJobType(res.data.jobType)
				setDateOfBirth(res.data.dateOfBirth)
				setFirstDayOfWork(res.data.firstDayOfWork)
				setSickDaysAvailable(res.data.sickDaysAvailable)
				setDaysOffAvailable(res.data.daysOffAvailable)
				setVacationDaysAvailable(res.data.vacationDaysAvailable)
				setAccountStatus(res.data.accountStatus)
				setLimitedAccessToCambridgeAdmin(res.data.limitedAccessToCambridgeAdmin)
			})
			.catch((err) => console.log(err))
	}

	const toggleAddDiscountModal = () => {
		setIsModalOpen(!isModalOpen)
	}
	const toggleHomePopupSettingsModal = () => {
		setIsModalForHomePopupSettingsOpen(!isModalForHomePopupSettingsOpen)
	}
	const toggleUsersSessionsModal = () => {
		setIsModalForUsersSessionsOpen(!isModalForUsersSessionsOpen)
	}
	const toggleLogsModal = () => {
		setIsModalForLogsOpen(!isModalForLogsOpen)
	}
	const toggleViewDiscountsModal = () => {
		setIsModalToViewOpen(!isModalToViewOpen)
	}

	const toggleEmployeeFormFunction = () => {
		if (!employees.length) {
			getAllEmployees()
		}
		setToggleEmployeeForm(!toggleEmployeeForm)
		setToggleCommissionAdmin(false)
		setCommissionSearch(false)
		setShowClientsList(false)
		setShowDaycarepassesList(false)
	}
	const toggleCommissionsHandler = () => {
		setToggleCommissionAdmin(!toggleCommissionAdmin)
		setToggleEmployeeForm(false)
		setCommissionSearch(false)
		setShowClientsList(false)
		setShowDaycarepassesList(false)
	}
	const toggleCommissionSearch = () => {
		setCommissionSearch(!commissionSearch)
		setToggleCommissionAdmin(false)
		setToggleEmployeeForm(false)
		setShowClientsList(false)
		setShowDaycarepassesList(false)
	}
	const toggleClientsList = () => {
		setShowClientsList(!showClientsList)
		setCommissionSearch(false)
		setToggleCommissionAdmin(false)
		setToggleEmployeeForm(false)
		setShowDaycarepassesList(false)
	}
	const toggleDaycarePassesList = () => {
		setShowDaycarepassesList(!showDaycarePassesList)
		setCommissionSearch(false)
		setToggleCommissionAdmin(false)
		setToggleEmployeeForm(false)
		setShowClientsList(false)
	}

	const handleChange = (e) => {
		const value = e.target.value
		const inputName = e.target.name
		if (inputName === "employeeId") {
			setEmployeeId(value)
		}
		if (inputName === "firstName") {
			setFirstName(value)
		}
		if (inputName === "lastName") {
			setLastName(value)
		}
		if (inputName === "username") {
			setUsername(value)
		}
		if (inputName === "email") {
			setEmail(value)
		}
		if (inputName === "wage") {
			setWage(value)
		}
		if (inputName === "dateOfBirth") {
			setDateOfBirth(value)
		}
		if (inputName === "firstDayOfWork") {
			setFirstDayOfWork(value)
		}
		if (inputName === "sickDaysAvailable") {
			setSickDaysAvailable(value)
		}
		if (inputName === "daysOffAvailable") {
			setDaysOffAvailable(value)
		}
		if (inputName === "vacationDaysAvailable") {
			setVacationDaysAvailable(value)
		}
		if (inputName === "jobType") {
			setJobType(value)
		}
		if (inputName === "employeePassword") {
			setEmployeePassword(value)
		}
		if (inputName === "employeePassword2") {
			setEmployeePassword2(value)
		}
	}

	const handleDeleteEmployee = async (id) => {
		setLoadingAxiosReq(true)
		await axios
			.delete("/auth/employees/" + id, {
				headers: { Authorization: `JWT ${accessString}` },
			})
			.then(() => {
				setLoadingAxiosReq(false)
				getAllEmployees()
			})
			.catch((err) => {
				setLoadingAxiosReq(false)

				console.log(err)
			})
	}

	const onSelectedChanged = (value) => {
		setJobType(value.value)
	}

	const onSelectedChangedLocation = (value) => {
		setLocation(value.value)
	}

	const onSelectAccStatus = (value) => {
		setAccountStatus(value.value)
	}

	const onSelectLimitToCambridge = (value) => {
		setLimitedAccessToCambridgeAdmin(value.value)
	}

	const toggleCommissionModalHandler = (mode, id) => {
		setCommissionModalMode(mode)
		setSalaryIdForUpdate(id)
		setCommissionHandlerOpenModal(!commissionHandlerOpenModal)
		setShowDaycarepassesList(false)
	}

	const submitUpdateHandler = async (e) => {
		e.preventDefault()
		let infoUpdated = {
			firstName: firstName,
			lastName: lastName,
			username: username,
			email: email,
			location: location,
			wage: wage,
			jobType: jobType,
			dateOfBirth: dateOfBirth,
			firstDayOfWork: firstDayOfWork,
			sickDaysAvailable: sickDaysAvailable,
			daysOffAvailable: daysOffAvailable,
			vacationDaysAvailable: vacationDaysAvailable,
			accountStatus: accountStatus,
			limitedAccessToCambridgeAdmin: limitedAccessToCambridgeAdmin,
		}

		if (employeePassword) {
			infoUpdated.password = employeePassword
			if (employeePassword !== employeePassword2) {
				return setErrorMsg("Passwords don't match!")
			}
		}

		setLoadingAxiosReq(true)
		await axios
			.put(`/auth/employees/${employeeId}`, infoUpdated)
			.then(() => {
				getAllEmployees()
				setLoadingAxiosReq(false)
				setModalToEditEmployee(false)
			})
			.catch((err) => {
				setLoadingAxiosReq(false)
				console.log(err)
			})
	}

	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
	}

	const getAllEmployeesByCommission = async () => {
		await axios
			.get("/auth/employees", {
				headers: { Authorization: `JWT ${accessString}` },
			})
			.then((res) => {
				let onlyEmployees = res.data.filter(
					(user) =>
						user.jobType !== "admin" &&
						user.jobType !== "receptionist" &&
						user.jobType !== "receptionistCalendars"
				)
				let formattedByCommission = onlyEmployees.map((item) => ({
					label: capitalizeFirstLetter(item.firstName),
					value: item.firstName,
				}))
				setAllEmployeesByCommission(formattedByCommission)
			})
			.catch((err) => console.log(err))
	}

	const salarySearchResultsList = salarySearchResults.length ? (
		salarySearchResults.map((data) => (
			<div className="result-wrapper" key={data.id}>
				<p className="pl-2 pt-2 search-result-info-wrapper">
					<span>
						Date: <b>{data.date}</b> Name:{" "}
						<b>{data.groomerName.toUpperCase()}</b>
					</span>
					<span>
						Pet Name/Breed: <b>{data.nameBreed.toUpperCase()}</b>
					</span>
					<span>
						Cost: <b>${data.cost ? data.cost : 0}</b> -- Tips:{" "}
						<b>${data.tip ? data.tip : 0}</b>
					</span>
				</p>
				<p
					onClick={() => handleDeleteCommission(data.id)}
					className="text-danger edit-btn-salary"
				>
					DELETE
				</p>
				<p
					onClick={() => toggleCommissionModalHandler("edit", data.id)}
					className="text-info edit-btn-salary"
				>
					Edit Data
				</p>
			</div>
		))
	) : (
		<p className="text-center">{searchResultMsg}</p>
	)

	const employeesList = employees.length ? (
		employees.map((employee) => (
			<tr
				key={employee.id}
				className="border-b text-black bg-white hover:bg-gray-50 text-sm transition-all"
			>
				<td className="px-6 py-4 whitespace-nowrap">{employee.jobType}</td>
				<td className="px-6 py-4 whitespace-nowrap">{employee.firstName}</td>
				<td className="px-6 py-4 whitespace-nowrap">{employee.lastName}</td>
				<td className="px-6 py-4 whitespace-nowrap">{employee.email}</td>
				<td className="px-6 py-4 whitespace-nowrap">{employee.location}</td>
				<td className="px-6 py-4 whitespace-nowrap">{employee.wage}</td>
				<td className="px-6 py-4 whitespace-nowrap">{employee.dateOfBirth}</td>
				<td className="px-6 py-4 whitespace-nowrap">
					{employee.firstDayOfWork}
				</td>
				<td className="px-6 py-4 whitespace-nowrap">
					{employee.sickDaysAvailable}
				</td>
				<td className="px-6 py-4 whitespace-nowrap">
					{employee.daysOffAvailable}
				</td>
				<td className="px-6 py-4 whitespace-nowrap">
					{employee.vacationDaysAvailable}
				</td>
				<td className="px-6 py-4 whitespace-nowrap">
					{employee.accountStatus}
				</td>
				<td className="px-6 py-4 whitespace-nowrap">
					{employee.limitedAccessToCambridgeAdmin ? "Yes" : "No"}
				</td>
				<td className="px-6 py-4 whitespace-nowrap flex space-x-2">
					{loadingAxiosReq ? (
						<LoadPage />
					) : (
						<button
							className="bg-red-600 text-white px-3 py-2 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
							onClick={(e) => {
								if (
									window.confirm(
										`Are you sure you wish to delete ${employee.firstName} ${employee.lastName} permanently?`
									)
								)
									handleDeleteEmployee(employee.id)
							}}
						>
							Delete
						</button>
					)}
					{loadingAxiosReq ? (
						<LoadPage />
					) : (
						<button
							onClick={getEmployeeInfo}
							value={employee.id}
							className="bg-green-600 text-white px-3 py-2 rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
						>
							Edit
						</button>
					)}
				</td>
			</tr>
		))
	) : (
		<tr>
			<td colSpan="14" className="text-center py-4 text-black">
				No Employees in database
			</td>
		</tr>
	)

	if (error) {
		return (
			<div
				style={{
					marginLeft: "10%",
					fontSize: "30px",
					height: "100vh",
					marginTop: "100px",
				}}
			>
				...Problem fetching user data. Please refresh page or login again... You
				may not have permission to see this page
				<span role="img" aria-label="Face With Rolling Eyes Emoji">
					🙄
				</span>
			</div>
		)
	}
	if (isLoading) {
		return (
			<div
				style={{
					marginTop: "100px",
					marginLeft: "10%",
					fontSize: "30px",
					height: "100vh",
				}}
			>
				Loading User Data...
			</div>
		)
	}
	if (userRole !== "admin") {
		return (
			<div
				className="text-center"
				style={{
					marginTop: "150px",
					fontSize: "30px",
					height: "100vh",
				}}
			>
				Error 401 Unauthorized
			</div>
		)
	}

	return (
		<div className="adminPageContainer">
			<main style={{ marginTop: "58px" }}></main>
			<div className="row">
				<div className="col-lg-12 text-align-center">
					<div className="admin-panel-btns-container bg-black pt-2">
						<Link
							to="/auth/reception"
							className="bg-slate-500 text-white py-2 px-4 rounded w-full sm:w-auto mb-2 flex items-center justify-center hover:bg-slate-700 transition-colors"
						>
							<FaExternalLinkAlt className="inline-block mr-1" />
							Receptionist Page <i className="fas fa-cog ml-1"></i>
						</Link>

						<Link
							className="bg-slate-700 text-white py-2 px-4 rounded w-full sm:w-auto mb-2 flex items-center justify-center hover:bg-slate-700 transition-colors"
							to="/auth/commission"
						>
							<FaExternalLinkAlt className="inline-block mr-1" />
							Commissions Page <i className="fas fa-dollar-sign ml-1"></i>
						</Link>

						<button
							className="bg-slate-500 text-white py-2 px-4 rounded w-full sm:w-auto mb-2 flex items-center justify-center hover:bg-slate-700 transition-colors"
							onClick={toggleCommissionsHandler}
						>
							<i className="fas fa-chevron-circle-up mr-1"></i> Commissions
							<i className="fas fa-chevron-circle-down"></i>
						</button>

						<button
							className="bg-slate-700 text-white py-2 px-4 rounded w-full sm:w-auto mb-2 flex items-center justify-center hover:bg-slate-700 transition-colors"
							onClick={() => toggleCommissionModalHandler("add")}
						>
							+ Commissions
						</button>

						<button
							className="bg-slate-500 text-white py-2 px-4 rounded w-full sm:w-auto mb-2 flex items-center justify-center hover:bg-slate-700 transition-colors"
							onClick={toggleCommissionSearch}
						>
							<i className="fas fa-search mr-1"></i> Search Commissions
						</button>

						<button
							className="bg-slate-700 text-white py-2 px-4 rounded w-full sm:w-auto mb-2 flex items-center justify-center hover:bg-slate-700 transition-colors"
							onClick={toggleEmployeeFormFunction}
						>
							<i className="fas fa-chevron-circle-up mr-1"></i> Manage Employees
							<i className="fas fa-chevron-circle-down"></i>
						</button>

						<button
							className="bg-slate-500 text-white py-2 px-4 rounded w-full sm:w-auto mb-2 flex items-center justify-center hover:bg-slate-700 transition-colors"
							onClick={toggleClientsList}
						>
							<i className="fas fa-chevron-circle-up mr-1"></i> Clients List
							<i className="fas fa-chevron-circle-down"></i>
						</button>

						<button
							className="bg-slate-700 text-white py-2 px-4 rounded w-full sm:w-auto mb-2 flex items-center justify-center hover:bg-slate-700 transition-colors"
							onClick={toggleDaycarePassesList}
						>
							<i className="fas fa-chevron-circle-up mr-1"></i> Daycare P. List
							<i className="fas fa-chevron-circle-down"></i>
						</button>

						<div className="flex flex-col sm:flex-row relative w-full sm:w-auto">
							<button
								className="bg-slate-500 text-white py-2 px-4 rounded w-full sm:w-auto mb-2 sm:mb-0 flex items-center justify-center hover:bg-slate-700 transition-colors"
								onClick={() => setShowDiscountButtons(!showDiscountButtons)}
							>
								<i className="fas fa-chevron-circle-up mr-1"></i> Discounts
								<i className="fas fa-chevron-circle-down"></i>
							</button>
							{showDiscountButtons && (
								<div className="absolute top-10 w-full bg-black px-1 pb-1 rounded-b-md">
									<button
										onClick={toggleViewDiscountsModal}
										className="bg-slate-500 text-white py-2 px-4 rounded w-full mt-1 sm:mt-0 hover:bg-slate-600 transition-colors"
									>
										<FaRegEye className="inline mr-2" /> Discounts
									</button>
									<button
										onClick={toggleAddDiscountModal}
										className="bg-slate-500 text-white py-2 px-4 rounded w-full mt-1 sm:mt-0  hover:bg-slate-600 transition-colors"
									>
										<BsPlusSquareFill className="inline mr-2" />
										Discount
									</button>
								</div>
							)}
						</div>
						<div className="flex w-full gap-1 px-1 pb-1">
							<button
								onClick={toggleLogsModal}
								className="bg-slate-500 text-white py-2 px-4 rounded w-full mt-1 sm:mt-0  hover:bg-slate-600 transition-colors"
							>
								Logs
							</button>
							<button
								onClick={toggleHomePopupSettingsModal}
								className="bg-slate-700 text-white py-2 px-4 rounded w-full mt-1 sm:mt-0  hover:bg-slate-600 transition-colors"
							>
								Home Popup
							</button>
							<button
								onClick={toggleUsersSessionsModal}
								className="bg-slate-500 text-white py-2 px-4 rounded w-full mt-1 sm:mt-0  hover:bg-slate-600 transition-colors"
							>
								Users Sessions
							</button>
						</div>
					</div>

					{commissionSearch ? (
						<>
							<hr />
							<h5 className="mt-2">Search for Commissions or Salaries</h5>
							<form
								className="search-commission-form mt-1"
								onSubmit={searchCommissionHandler}
							>
								<div>
									<label className="mr-2" htmlFor="">
										Start Date
									</label>
									<DatePicker
										className="form-commission"
										selected={searchDateStart}
										onChange={(date) => setSearchDateStart(date)}
										dateFormat="yyyy-MM-dd"
										placeholderText="Start Date"
									/>
								</div>
								<div>
									<label className="mr-2" htmlFor="">
										End Date
									</label>
									<DatePicker
										className="form-commission"
										selected={searchDateEnd}
										onChange={(date) => setSearchDateEnd(date)}
										dateFormat="yyyy-MM-dd"
										placeholderText="End Date"
									/>
								</div>
								<div>
									<label className="mr-2" htmlFor="">
										Employee Name
									</label>
									<Select
										onChange={(value) => setEmployeeName(value.value)}
										options={allEmployeesByCommission}
									/>
								</div>
								<button className="btn btn-success px-5">
									{salarySearchResults.length ? "Refresh results" : "Search"}
								</button>
								<button
									onClick={(e) => {
										e.preventDefault()
										setSearchDateStart("")
										setSearchDateEnd("")
										setEmployeeName("")
										setSalarySearchResults([])
									}}
									className="btn btn-danger"
								>
									Reset Inputs
								</button>
							</form>
							<hr />
							<div className="salarySearchResults-container">
								{salarySearchResultsList}
							</div>
						</>
					) : null}

					{toggleEmployeeForm ? (
						<div className="admin-panel-sec-container">
							<div className="row">
								<div className="col-md-2">
									<EmployeeSignupForm />
								</div>
								<div className="col-md-10">
									<h4 className="text-md">STAFF LIST</h4>
									<Table responsive dark>
										<thead>
											<tr>
												<th className="text-sm">Position</th>
												<th className="text-sm">F. Name</th>
												<th className="text-sm">L. Name</th>
												<th className="text-sm">Email</th>
												<th className="text-sm">Location</th>
												<th className="text-sm">Wage</th>
												<th className="text-sm">DOB</th>
												<th className="text-sm">1st DAY</th>
												<th className="text-sm">Sick Days Avail</th>
												<th className="text-sm">Days Off Avail</th>
												<th className="text-sm">Vacation Days Left</th>
												<th className="text-sm">Acc Status</th>
												<th className="text-sm">Limited Access Cambridge</th>
												<th className="text-sm">Actions</th>
											</tr>
										</thead>
										<tbody>{employeesList}</tbody>
									</Table>
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>
			<ModalToEditEmployee
				openModal={modalToEditEmployee}
				toggleModalToEditEmployee={() => {
					setModalToEditEmployee(!modalToEditEmployee)
				}}
				submitFormAll={submitUpdateHandler}
				handleChange={handleChange}
				employeeId={employeeId}
				firstName={firstName}
				lastName={lastName}
				username={username}
				email={email}
				location={location}
				wage={wage}
				jobType={jobType}
				dateOfBirth={dateOfBirth}
				firstDayOfWork={firstDayOfWork}
				sickDaysAvailable={sickDaysAvailable}
				daysOffAvailable={daysOffAvailable}
				vacationDaysAvailable={vacationDaysAvailable}
				employeePassword={employeePassword}
				employeePassword2={employeePassword2}
				accountStatus={accountStatus}
				limitedAccessToCambridgeAdmin={limitedAccessToCambridgeAdmin}
				loadingAxiosReq={loadingAxiosReq}
				errMsg={errMsg}
				onSelectedChanged={onSelectedChanged}
				onSelectedChangedLocation={onSelectedChangedLocation}
				onSelectAccStatus={onSelectAccStatus}
				onSelectLimitToCambridge={onSelectLimitToCambridge}
			/>
			<ModalForCommissions
				openModal={commissionHandlerOpenModal}
				toggle={() =>
					setCommissionHandlerOpenModal(!commissionHandlerOpenModal)
				}
				mode={commissionModalMode}
				salaryIdForUpdate={salaryIdForUpdate}
				searchCommissionHandler={searchCommissionHandler}
				refreshSearchResults={refreshSearchResults}
				salarySearchResults={salarySearchResults}
			/>
			{toggleCommissionAdmin ? (
				<SpecialCalculations
					modalOpen={modalForSpecialCalculations}
					toggleModal={() =>
						setModalForSpecialCalculations(!modalForSpecialCalculations)
					}
				/>
			) : null}

			{showClientsList ? <ClientsList /> : null}
			{showDaycarePassesList ? <DaycarePassesList /> : null}

			<ModalGeneralPurpose
				toggleModal={toggleUsersSessionsModal}
				isModalOpen={isModalForUsersSessionsOpen}
				modalContent={SessionsTable} // Component
				modalSize={"xl"}
				marginTop={0}
			/>

			<ModalGeneralPurpose
				toggleModal={toggleLogsModal}
				isModalOpen={isModalForLogsOpen}
				modalContent={Logs} // Component
				modalSize={"xl"}
				marginTop={0}
				// style={{ maxHeight: "90vh", overflowY: "auto" }}
			/>
			<ModalGeneralPurpose
				toggleModal={toggleHomePopupSettingsModal}
				isModalOpen={isModalForHomePopupSettingsOpen}
				modalContent={HomePopupSettings} // Component
				modalSize={"xl"}
				marginTop={0}
				// style={{ maxHeight: "90vh", overflowY: "auto" }}
			/>
			<ModalGeneralPurpose
				toggleModal={toggleAddDiscountModal}
				isModalOpen={isModalOpen}
				modalContent={DiscountForm} // Component
				modalSize={"md"}
			/>
			<ModalGeneralPurpose
				toggleModal={toggleViewDiscountsModal}
				isModalOpen={isModalToViewOpen}
				modalContent={DiscountList} // Component
				modalSize={"xl"}
			/>
		</div>
	)
}

export default Index
