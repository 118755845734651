import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { RegistrationContext } from "../../contexts/registrationStatusContext"
import { CiCircleRemove } from "react-icons/ci"
import { loadStripe } from "@stripe/stripe-js"
import "./style.scss"

const S_P_K =
	"pk_live_51OqlUTCZA17Y7kpHSYVSvRgB9TAmcOGEaCs2KSk1rOdiVqmihr6IcHYpdS8JT15stK7vRO63BnZle3rTquLP667000mxrpN0sC"
// "pk_test_51OqlUTCZA17Y7kpHBxVoO03oc8ghQNTneFed6QRbq9hSc7cp9UppcFFYY1oL2bhA2A70PE3y0o5teqY73WTWKMcf00EpDg6dOd"

const stripePromise = loadStripe(S_P_K)

const CheckoutPage = () => {
	const { cart, updateCart, handleRemoveItem, resetCartHandler } =
		useContext(RegistrationContext)
	const [isLoading, setIsLoading] = useState(false)
	const [totalPrice, setTotalPrice] = useState(0)
	const [subTotalPrice, setSubTotalPrice] = useState(0)
	const [taxPrice, setTaxPrice] = useState(0)
	const [email, setEmail] = useState("")
	const [error, setError] = useState(null)
	const navigate = useNavigate()

	useEffect(() => {
		let subTotal = 0
		let taxTotal = 0

		cart.forEach((item) => {
			const itemTotal = item.price * item.quantity
			subTotal += itemTotal

			if (item.type !== "gift_card") {
				taxTotal += (itemTotal * 13) / 100
			}
		})

		setSubTotalPrice(subTotal)
		setTaxPrice(taxTotal)
		setTotalPrice(subTotal + taxTotal)
	}, [cart])

	const handleCheckout = async () => {
		if (!email) {
			setError("Please enter a valid email address.")
			return
		}

		setIsLoading(true)
		try {
			const { data } = await axios.post("/api/store/checkout-session", {
				cart: cart.map((item) => ({
					quantity: item.quantity,
					productId: item.id,
					price: item.price,
					type: item.type,
					imgUrl: item.imgUrl,
					name: item.name,
				})),
				email,
				totalPrice,
				subTotalPrice,
			})

			// Redirect to Stripe Checkout
			const stripe = await stripePromise
			await stripe.redirectToCheckout({ sessionId: data.sessionId })
		} catch (error) {
			console.error("Failed to create checkout session", error)
			setError("There was an error processing your checkout.")
		} finally {
			setIsLoading(false)
		}
	}

	const handleQuantityChange = (id, newQuantity) => {
		if (newQuantity <= 0) return
		updateCart((prevCart) =>
			prevCart.map((item) =>
				item.id === id ? { ...item, quantity: newQuantity } : item
			)
		)
	}

	if (cart.length === 0) {
		return (
			<div className="container mx-auto py-10 mt-16 text-center">
				<h2 className="text-2xl text-main font-bold">Your cart is empty.</h2>
				<div className="flex justify-center">
					<img className="w-30" src="/images/funny_dog.png" alt="funny dog" />
				</div>
				<button
					onClick={() => navigate("/store")}
					className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
				>
					Go back to Store
				</button>
			</div>
		)
	}

	return (
		<div className="container text-main checkout_page mx-auto px-4">
			<h1 className="text-2xl md:text-3xl text-main font-bold mb-6 text-center mt-20">
				Checkout
			</h1>
			<hr />
			<div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-6">
				{/* Cart Items */}
				<div className="lg:col-span-2 bg-white border p-4 rounded-lg shadow-sm">
					<h3 className="text-xl text-main font-semibold mb-4">Your Items</h3>
					<ul className="space-y-4">
						{cart.map((item) => (
							<li
								key={item.id}
								className="border rounded-lg overflow-hidden shadow-md"
							>
								<div className="flex flex-col md:flex-row">
									{/* Product Name (Mobile View) */}
									<div className="block md:hidden bg-gray-100 p-2 text-center">
										<h3 className="text-main text-lg font-bold">{item.name}</h3>
									</div>

									{/* Product Image */}
									<div className="w-full md:w-1/2">
										<img
											className="w-full object-cover shadow-md"
											src={item.imgUrl}
											alt={item.name}
										/>
									</div>

									{/* Product Details */}
									<div className="flex-1 p-4">
										{/* Product Name (Desktop View) */}
										<div className="hidden md:block">
											<h3 className="text-lg font-bold">{item.name}</h3>
										</div>

										<div className="flex justify-between items-center mt-2">
											<div className="flex items-center space-x-4">
												<CiCircleRemove
													className="cursor-pointer"
													size={30}
													color="red"
													onClick={() => handleRemoveItem(item.id)}
												/>

												<div className="flex items-center space-x-2">
													<label htmlFor={`qty-${item.id}`} className="text-sm">
														Qty:
													</label>
													<input
														id={`qty-${item.id}`}
														type="number"
														min="1"
														value={item.quantity}
														onChange={(e) =>
															handleQuantityChange(
																item.id,
																Number(e.target.value)
															)
														}
														className="w-16 border rounded-md px-2 py-1 text-sm focus:outline-none"
													/>
												</div>
											</div>

											<span className="text-gray-700 font-medium">
												${(item.price * item.quantity).toFixed(2)}
											</span>
										</div>
									</div>
								</div>
							</li>
						))}
					</ul>
				</div>

				{/* Summary Section */}
				<div className="bg-white border p-4 rounded-lg shadow-sm">
					<h3 className="text-xl font-semibold mb-4">Summary</h3>
					<div className="space-y-4">
						<div className="flex justify-between">
							<span>Subtotal:</span>
							<span>${subTotalPrice.toFixed(2)}</span>
						</div>
						<div className="flex justify-between">
							<span>Estimated GST/HST:</span>
							<span>${taxPrice.toFixed(2)}</span>
						</div>
						<div className="flex justify-between">
							<span>Total Price:</span>
							<span>${totalPrice.toFixed(2)}</span>
						</div>

						{/* Email Input */}
						<div>
							<label htmlFor="email" className="block text-sm font-semibold">
								Email Address
							</label>
							<input
								id="email"
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								className="mt-2 text-black w-full border px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
								placeholder="Enter your email"
							/>
						</div>

						{/* Error Message */}
						{error && <p className="text-red-500">{error}</p>}

						{/* Checkout Button */}
						<button
							onClick={handleCheckout}
							disabled={isLoading}
							className={`mt-4 w-full bg-green-500 text-white px-4 py-2 rounded-md ${
								isLoading
									? "opacity-50 cursor-not-allowed"
									: "hover:bg-green-600"
							}`}
						>
							{isLoading ? "Processing..." : "Checkout"}
						</button>

						{/* Payment Logos */}
						<div className="flex justify-center mt-4 space-x-4">
							<img src="/images/visa.png" alt="Visa" className="w-12 h-auto" />
							<img
								src="/images/mastercard.png"
								alt="Mastercard"
								className="w-12 h-auto"
							/>
							<img src="/images/amex.png" alt="Amex" className="w-12 h-auto" />
							<img src="/images/jcb.png" alt="Jcb" className="w-12 h-auto" />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CheckoutPage
