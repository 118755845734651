import React, { useRef } from "react"
import { gsap } from "gsap"
import { useLayoutEffect } from "react"
import { FaInfoCircle } from "react-icons/fa"
import { Tooltip as ReactTooltip } from "react-tooltip"

const BookingCard = ({ extraImg, image, title, description, onClick }) => {
	const tl = useRef(null)
	const container = useRef(null)
	const tooltipId = `tooltip-${title.replace(/\s+/g, "-").toLowerCase()}`

	useLayoutEffect(() => {
		let ctx = gsap.context(() => {
			tl.current = gsap.timeline({ paused: true })
			tl.current.to(container.current, {
				scale: 1.03,
				borderColor: "#65BFF8",
				backgroundColor: "#DDF2FF",
				boxShadow: "2px 4px 12px 0px rgba(0, 156, 255, 0.10)",
				duration: 0.2,
			})
		}, container)

		return () => ctx.revert()
	}, [])

	return (
		<div
			className="p-phone w-full rounded-default border-[1px] border-lightGray flex flex-col sm:flex-row items-center justify-start cursor-pointer gap-4 sm:gap-5"
			style={{ backgroundColor: "#FFFFFF" }}
			onClick={onClick}
			onMouseEnter={() => tl.current.play()}
			onMouseLeave={() => tl.current.reverse()}
			ref={container}
		>
			{extraImg && (
				<div className="flex items-center justify-center imageCard relative overflow-hidden w-[50px] sm:w-[70px] min-w-[50px] h-[50px] sm:h-[70px] rounded-full border-[6px] border-orange-500">
					<img
						src={extraImg}
						alt={title}
						className="w-full h-auto rounded-full"
					/>
				</div>
			)}

			<div className="flex items-center justify-center imageCard relative overflow-hidden w-[50px] sm:w-[70px] min-w-[50px] h-[50px] sm:h-[70px] rounded-full border-[1px] border-lightGray">
				<img src={image} alt={title} className="w-full h-auto rounded-full" />
			</div>

			<div className="flex-1 flex flex-col w-full text-center sm:text-left">
				<h5 className="mb-0 text-lightGray font-medium textCard flex items-center justify-center sm:justify-start">
					{title}{" "}
					{extraImg && (
						<>
							<FaInfoCircle
								data-tooltip-id={tooltipId}
								color="blue"
								className="inline ml-2"
							/>
							<ReactTooltip
								id={tooltipId}
								place="top"
								content="Express is a special service with an additional cost, tailored to your dog's behavior. A dedicated groomer will work exclusively on your pet, ensuring a faster grooming experience—typically completed in 1 to 2 hours, compared to the standard 2 to 4 hours."
								style={{
									maxWidth: "40vw",
									whiteSpace: "normal",
									zIndex: 9999,
								}}
								delayShow={200}
								delayHide={200}
								variant="info"
							/>
						</>
					)}
				</h5>
				<p className="mb-0 text-lightGray textCard">{description}</p>
			</div>
		</div>
	)
}

export default BookingCard
