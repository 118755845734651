//Function to define styling on Calendar's Admin's events
const eventStyleGetter = (event, start, end, isSelected) => {
	var style = {
		backgroundColor: "rgb(51, 156, 255)",
		borderRadius: "5px",
		opacity: 0.8,
		fontSize: "16px",
		color: "white",
		border: "1px solid blue",
		display: "block",
		paddingLeft: "12px",
		paddingRight: "12px",
		textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
	}
	if (event.appointment === "schedule") {
		style.backgroundColor = "red"
		style.color = "white"
	}
	if (event.appointment === "online") {
		style.backgroundColor = "white"
		style.color = "white"
	}
	if (event.appointment === "onlineBath") {
		style.backgroundColor = "white"
		style.color = "white"
		style.border = "aqua 1px solid"
	}
	if (event.appointment === "onlineCat") {
		style.backgroundColor = "white"
		style.color = "white"
		style.border = "4px solid pink"
	}
	if (event.appointment === "cancel") {
		style.backgroundColor = "purple"
	}

	if (event.appointment === "staffOnly") {
		style.backgroundColor = "white"
		style.color = "white"
		style.border = "1px solid rgb(107, 238, 111)"
	}
	if (event.appointment === "daycare") {
		if (event.title.includes("Spots available for Daycare")) {
			style.backgroundColor =
				event.title === "0 Spots available for Daycare" ? "red" : "black"
		} else {
			style.backgroundColor = "#98fc03"
		}
		style.color = event.title.includes("Spots available for Daycare")
			? "white"
			: "black"
		if (event.title.includes("Spots available for Daycare")) {
			style.textShadow = "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"
		} else {
			style.textShadow = "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white"
		}
	}
	if (event.appointment === "cat") {
		style.backgroundColor = "rgb(255, 187, 51)"
	}
	if (event.appointment === "bigDog") {
		style.backgroundColor = "rgb(0, 26, 51)"
		style.border = event.isExpress
			? "#fc08bb 3px solid"
			: "4px solid rgb(0, 255, 255)"
	}
	if (event.appointment === "mediumDog") {
		style.backgroundColor = "#0056b3"
		style.textShadow = "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"
		style.border = event.isExpress
			? "#fc08bb 3px solid"
			: "4px solid rgb(0, 255, 255)"
	}
	if (event.appointment === "bath") {
		style.backgroundColor = "rgb(0, 255, 255)"
		style.border = event.isExpress
			? "#fc08bb 3px solid"
			: "4px solid rgb(0, 255, 255)"
	}
	if (event.appointment === "bathSmallDog") {
		style.backgroundColor = "rgb(51, 156, 255)"
		style.border = event.isExpress
			? "#fc08bb 3px solid"
			: "4px solid rgb(0, 255, 255)"
	}
	if (event.appointment === "bathMediumDog") {
		style.backgroundColor = "#0056b3"
		style.border = event.isExpress
			? "#fc08bb 3px solid"
			: "4px solid rgb(0, 255, 255)"
	}
	if (event.appointment === "bathBigDog") {
		style.backgroundColor = "rgb(0, 26, 51)"
		style.border = event.isExpress
			? "#fc08bb 3px solid"
			: "4px solid rgb(0, 255, 255)"
	}
	//Request Conditionals
	if (event.appointment === "bigDogRequest") {
		style.backgroundColor = "rgb(0, 26, 51)"
		style.border = event.isExpress ? "#fc08bb 3px solid" : "5px dotted #e6b800"
	}
	if (event.appointment === "mediumDogRequest") {
		style.backgroundColor = "#0056b3"
		style.textShadow = "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"
		style.border = event.isExpress ? "#fc08bb 3px solid" : "5px dotted #e6b800"
	}
	if (event.appointment === "bathSmallDogRequest") {
		style.backgroundColor = "rgb(51, 156, 255)"
		style.border = event.isExpress
			? "#fc08bb 3px solid"
			: "4px dotted rgb(0, 255, 255)"
	}
	if (event.appointment === "bathMediumDogRequest") {
		style.backgroundColor = "#0056b3"
		style.border = event.isExpress
			? "#fc08bb 3px solid"
			: "4px dotted rgb(0, 255, 255)"
	}
	if (event.appointment === "bathBigDogRequest") {
		style.backgroundColor = "rgb(0, 26, 51)"
		style.border = event.isExpress
			? "#fc08bb 3px solid"
			: "4px dotted rgb(0, 255, 255)"
	}
	if (event.appointment === "bathRequest") {
		style.backgroundColor = "rgb(0, 255, 255)"
		style.border = event.isExpress ? "#fc08bb 3px solid" : "4px dotted #e6b800"
	}
	if (event.appointment === "appRequest") {
		style.backgroundColor = "rgb(51, 156, 255)"
		style.border = event.isExpress ? "#fc08bb 3px solid" : "5px dotted #e6b800"
	}
	return {
		style,
	}
}

export default eventStyleGetter
