import { useState, useEffect } from "react"
import axios from "axios"

export default function SessionsTable() {
	const [employees, setEmployees] = useState([])
	const [loading, setLoading] = useState(false)
	const accessString = localStorage.getItem("JWT")

	useEffect(() => {
		getAllEmployees()
	}, [])

	const getAllEmployees = async () => {
		setLoading(true)
		await axios
			.get("/auth/employees", {
				headers: { Authorization: `JWT ${accessString}` },
			})
			.then((res) => {
				setLoading(false)
				let onlyEmployees = res.data.filter((user) => {
					return user.jobType !== "admin"
				})
				setEmployees(onlyEmployees)
			})
			.catch((err) => {
				setLoading(false)
				console.log(err)
			})
	}

	const updateSessionStatus = async (id, sessionStatus) => {
		try {
			await axios.put(
				`/auth/session/update/${id}`,
				{ sessionStatus },
				{
					headers: { Authorization: `Bearer ${accessString}` },
				}
			)
			alert("Session status updated successfully")
			getAllEmployees()
		} catch (error) {
			console.error("Error updating session status:", error)
			alert("Failed to update session status.")
		}
	}

	return (
		<div className="p-4 bg-gray-50 min-h-screen">
			<div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6">
				<h2 className="text-2xl font-semibold mb-4">
					Employee Sessions Management
				</h2>
				{loading ? (
					<p>Loading employees...</p>
				) : (
					<table className="min-w-full bg-white border border-gray-200">
						<thead>
							<tr>
								<th className="py-2 px-4 border-b">First Name</th>
								<th className="py-2 px-4 border-b">Last Name</th>
								<th className="py-2 px-4 border-b">Allowed Session</th>
								<th className="py-2 px-4 border-b">Actions</th>
							</tr>
						</thead>
						<tbody>
							{employees.map((employee) => (
								<tr key={employee.id}>
									<td className="py-2 px-4 border-b">{employee.firstName}</td>
									<td className="py-2 px-4 border-b">{employee.lastName}</td>
									<td className="py-2 px-4 border-b text-center">
										<span
											className={`${
												employee.allowed_session
													? "text-green-400"
													: "text-red-500"
											}`}
										>
											{employee.allowed_session ? "Enabled" : "Disabled"}
										</span>
									</td>
									<td className="py-2 px-4 border-b flex justify-evenly">
										{/* <button
											className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
											onClick={() => updateSessionStatus(employee.id, true)}
										>
											Enable
										</button> */}
										<button
											disabled={!employee.allowed_session}
											className={`${
												employee.allowed_session ? "bg-red-500" : "bg-red-300"
											} text-white px-3 py-1 rounded`}
											onClick={() => updateSessionStatus(employee.id, false)}
										>
											Force Log out
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</div>
		</div>
	)
}
