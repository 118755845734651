import React, { useState, useEffect } from "react"
import "./style.scss"
import moment from "moment"
import DatePicker from "react-datepicker"
import axios from "axios"
import LegacyCommissionReport from "./LegacyCommissionReport"
import ReportContainer from "./ReportContainer"

const PRODUCTS = "products"
const DAYCARE = "daycare"
const GROOMER_1 = "paola"
const GROOMER_2 = "claudia"
const GROOMER_3 = "diana"
const GROOMER_4 = "frank"
const GROOMER_5 = "baths"
const GROOMER_6 = "peppa"
const GROOMER_7 = "adriana"
const GROOMER_8 = "mercedes"
const GROOMER_9 = "baths_cambridge"

const SpecialCalculations = () => {
	const [dateStart, setDateStart] = useState("")
	const [dateEnd, setDateEnd] = useState("")
	const [view, setView] = useState("Commissions")
	const [totalProjectedSales, setTotalProjectedSales] = useState(null)
	const [datePickerEnd, setDatePickerEnd] = useState("")
	const [datePickerStart, setDatePickerStart] = useState("")
	const [allEmployees, setAllEmployees] = useState([])
	const [totalSalesAllEmployees, setTotalSalesAllEmployees] = useState("")
	const [employeesForReport, setEmployeesForReport] = useState([])
	const [switchToOldFormat, setSwitchToOldFormat] = useState(false)
	const [reportReadyDaycare, setReportReadyDaycre] = useState(null)
	const [reportReadyGroomer1, setReportReadyGroomer1] = useState(null)
	const [reportReadyGroomer2, setReportReadyGroomer2] = useState(null)
	const [reportReadyGroomer3, setReportReadyGroomer3] = useState(null)
	const [reportReadyGroomer4, setReportReadyGroomer4] = useState(null)
	const [reportReadyGroomer5, setReportReadyGroomer5] = useState(null)
	const [reportReadyGroomer6, setReportReadyGroomer6] = useState(null)
	const [reportReadyGroomer7, setReportReadyGroomer7] = useState(null)
	const [reportReadyGroomer8, setReportReadyGroomer8] = useState(null)
	const [reportReadyGroomer9, setReportReadyGroomer9] = useState(null)
	const [reportReadyProducts, setReportReadyProducts] = useState(null)

	const accessString = localStorage.getItem("JWT")
	const getAllEmployees = async () => {
		await axios
			.get("/auth/employees", {
				headers: { Authorization: `JWT ${accessString}` },
			})
			.then((res) => {
				let onlyEmployees = res.data.filter(
					(user) =>
						user.jobType !== "admin" &&
						user.jobType !== "receptionist" &&
						user.jobType !== "receptionistCalendars"
				)
				setAllEmployees([...onlyEmployees, { id: 1, firstName: "products" }])
			})
			.catch((err) => console.log(err))
	}

	useEffect(() => {
		getAllEmployees()
		// eslint-disable-next-line
	}, [])

	const resetReport = () => {
		setReportReadyDaycre(null)
		setReportReadyGroomer1(null)
		setReportReadyGroomer2(null)
		setReportReadyGroomer3(null)
		setReportReadyGroomer4(null)
		setReportReadyGroomer5(null)
		setReportReadyGroomer6(null)
		setReportReadyGroomer7(null)
		setReportReadyGroomer8(null)
		setReportReadyGroomer9(null)
		setReportReadyProducts(null)
		setDateEnd("")
		setDateStart("")
		setTotalSalesAllEmployees("")
	}

	const removeEmployeeFromReportList = (id) => {
		const updatedEmployeeListForReport = employeesForReport.filter(
			(employee) => employee.id !== id
		)
		setEmployeesForReport(updatedEmployeeListForReport)
	}

	const generateReportHandler = async () => {
		const startDate = moment(datePickerStart).format("YYYY-MM-DD")
		const endDate = moment(datePickerEnd).format("YYYY-MM-DD")
		const employeeIds = []

		if (!datePickerStart || !datePickerEnd) {
			return alert("Add dates!")
		}
		employeesForReport.map((data) => employeeIds.push(data.firstName))

		await axios
			.post(
				`/auth/salaries_commissions/selectedEmployees/${startDate}/${endDate}`,
				employeeIds,
				{
					headers: { Authorization: `JWT ${accessString}` },
				}
			)
			.then(async (res) => {
				setEmployeesForReport([])
				setDateStart(startDate)
				setDateEnd(endDate)
				const filterCommission = (groomer) => {
					return res.data.filter((data) => data.groomerName === groomer)
				}
				const purchasesResponse = await axios.get(
					`/api/purchases/${startDate}/${endDate}`,
					{
						headers: { jwt: `${accessString}` },
					}
				)
				const filteredDataDaycare = filterCommission(DAYCARE) // PAOLA
				const filteredDataEmployee1 = filterCommission(GROOMER_1) // PAOLA
				const filteredDataEmployee2 = filterCommission(GROOMER_2) // CLAUDIA
				const filteredDataEmployee3 = filterCommission(GROOMER_3) // DIANA
				const filteredDataEmployee4 = filterCommission(GROOMER_4) // FRANK
				const filteredDataEmployee5 = filterCommission(GROOMER_5) // BATHS
				const filteredDataEmployee6 = filterCommission(GROOMER_6) // PEPPA
				const filteredDataEmployee7 = filterCommission(GROOMER_7) // ADRIANA
				const filteredDataEmployee8 = filterCommission(GROOMER_8) // MERCEDES
				const filteredDataEmployee9 = filterCommission(GROOMER_9) // BATHS_CAMBRIDGE
				const filteredDataProducts = filterCommission(PRODUCTS) // PRODUCTS

				const generateCommissionObj = (commissionTypeArr) => {
					const obj = {
						datePickerStart: startDate,
						datePickerEnd: endDate,
						groomerName: "",
						totalSales: "",
						totalCommission: "",
						totalTips: "",
					}
					let totalDailyResultCost = commissionTypeArr.map((item) => item.cost)
					let totalDailyResultTips = commissionTypeArr.map((item) => item.tip)
					let numberOfDaysWorked = commissionTypeArr.map((item) => item.date)
					const qualifiesFor60Commission =
						[...new Set(numberOfDaysWorked)].length > 5
					let dayOffCommissions = commissionTypeArr.filter(
						(item) =>
							moment(item.date).day() === item.dayOff ||
							moment(item.date).day() === 0 // Includes Sunday at 60% rate.
					)
					let normalDayCommissions = commissionTypeArr.filter(
						(item) =>
							moment(item.date).day() !== item.dayOff &&
							moment(item.date).day() !== 0 // Includes Sunday at 60% rate
					)
					let dayOffOvertimeTotalCost = dayOffCommissions
						.map((item) => item.cost)
						.reduce((a, b) => a + b, 0)
					let normalDayTotalCost =
						(normalDayCommissions
							.map((item) => item.cost)
							.reduce((a, b) => a + b, 0) *
							50) /
						100
					const sixtyPercentCommission = (dayOffOvertimeTotalCost * 60) / 100
					const fiftyPercentCommission = (dayOffOvertimeTotalCost * 50) / 100

					const finalPercent = qualifiesFor60Commission
						? sixtyPercentCommission
						: fiftyPercentCommission

					const totalTips = Number(
						totalDailyResultTips.reduce((a, b) => a + b, 0).toFixed(2)
					)
					const totalCommission = normalDayTotalCost + finalPercent
					const totalSalesAllEmployees = res.data
						.map((item) => item.cost)
						.reduce((a, b) => a + b, 0)

					setTotalSalesAllEmployees(totalSalesAllEmployees)

					commissionTypeArr.map((data) => {
						const totalSales = totalDailyResultCost.reduce((a, b) => a + b, 0)
						if (!obj.groomerName) {
							obj.groomerName = data.groomerName
							obj.totalSales = totalSales.toFixed(2)
							obj.totalTips = totalTips
						}
						if (data.salaryType === "commission") {
							obj.totalCommission = totalCommission
							obj.workedOnDayOff = qualifiesFor60Commission
							obj.fiftyPercentCommission = (
								(normalDayCommissions
									.map((item) => item.cost)
									.reduce((a, b) => a + b, 0) *
									50) /
								100
							).toFixed(2)
							obj.sixtyPercentCommission = sixtyPercentCommission.toFixed(2)
							obj.grandTotal = totalCommission + totalTips
						}
						// Claudia
						if (data.groomerName === GROOMER_2) {
							const salary = 1200
							obj.valueForPaystub = salary
							obj.valueForCash = (totalCommission + totalTips - salary).toFixed(
								2
							)
						}
						// Diana
						if (data.groomerName === GROOMER_3) {
							obj.valueForPaystub = (totalCommission / 2).toFixed(2)
							obj.valueForCash = (totalCommission / 2 + totalTips).toFixed(2)
						}
						// Adriana
						if (data.groomerName === GROOMER_7) {
							const salary = 800
							obj.valueForPaystub = salary
							obj.valueForCash = (totalCommission + totalTips - salary).toFixed(
								2
							)
							obj.fiftyPercentCommission = (
								(normalDayCommissions
									.map((item) => item.cost)
									.reduce((a, b) => a + b, 0) *
									50) /
								100
							).toFixed(2)
						}
						if (data.salaryType === "hourly") {
							obj.totalHours = "not calculated yet"
						}
						return
					})
					return obj
				}

				setReportReadyProducts(purchasesResponse.data)
				if (filteredDataDaycare.length) {
					const objCreated = generateCommissionObj(filteredDataDaycare)
					setReportReadyDaycre(objCreated)
				}
				if (filteredDataEmployee1.length) {
					const objCreated = generateCommissionObj(filteredDataEmployee1)
					setReportReadyGroomer1(objCreated)
				}
				if (filteredDataEmployee2.length) {
					const objCreated = generateCommissionObj(filteredDataEmployee2)
					setReportReadyGroomer2(objCreated)
				}
				if (filteredDataEmployee3.length) {
					const objCreated = generateCommissionObj(filteredDataEmployee3)
					setReportReadyGroomer3(objCreated)
				}
				if (filteredDataEmployee4.length) {
					const objCreated = generateCommissionObj(filteredDataEmployee4)
					setReportReadyGroomer4(objCreated)
				}
				if (filteredDataEmployee5.length) {
					const objCreated = generateCommissionObj(filteredDataEmployee5)
					setReportReadyGroomer5(objCreated)
				}

				// PEPPA's CALCULATION
				if (filteredDataEmployee6.length) {
					const objCreated = generateCommissionObj(filteredDataEmployee6)
					setReportReadyGroomer6(objCreated)
				}

				// ADRIANA's CALCULATION
				if (filteredDataEmployee7.length) {
					const objCreated = generateCommissionObj(filteredDataEmployee7) // ADD PEPPA
					setReportReadyGroomer7(objCreated)
				}

				// MERCEDES' CALCULATION
				if (filteredDataEmployee8.length) {
					const objCreated = generateCommissionObj(filteredDataEmployee8)
					setReportReadyGroomer8(objCreated)
				}

				// BATHS CAMBRIDGE'S CALCULATION
				if (filteredDataEmployee9.length) {
					const objCreated = generateCommissionObj(filteredDataEmployee9)
					setReportReadyGroomer9(objCreated)
				}

				setDatePickerEnd("")
				setDatePickerStart("")
			})
			.catch((err) => console.log(err))
	}

	const toggleView = (viewType) => {
		setView(viewType)
		setDatePickerStart("")
		setDatePickerEnd("")
		setDateStart("")
		setDateEnd("")
		setTotalProjectedSales(0)
	}

	const calculateSalesHandler = async () => {
		const startDate = moment(datePickerStart).format("YYYY-MM-DD")
		const endDate = moment(datePickerEnd).format("YYYY-MM-DD")
		const employeeIds = []

		if (!datePickerStart || !datePickerEnd) {
			return alert("Add dates!")
		}

		employeesForReport.map((data) => employeeIds.push(data.firstName))

		try {
			const response = await axios.post(
				`/auth/sales/projected/${startDate}/${endDate}`,
				employeeIds,
				{
					headers: { Authorization: `JWT ${accessString}` },
				}
			)
			setTotalProjectedSales(response.data.totalProjectedSales)
			setEmployeesForReport([])
			setDateStart(startDate)
			setDateEnd(endDate)
		} catch (err) {
			console.error(err)
		}
	}

	const employees =
		allEmployees &&
		allEmployees.map((employee) => {
			const isBtnDisabled =
				employeesForReport.length &&
				employeesForReport.find((item) => item.id === employee.id)
			return (
				<button
					key={employee.id}
					onClick={() =>
						setEmployeesForReport([...employeesForReport, employee])
					}
					className="btn btn-dark "
					disabled={isBtnDisabled}
				>
					+ {employee.firstName.toUpperCase()}
				</button>
			)
		})

	const reportList =
		employeesForReport &&
		employeesForReport.map((employee) => {
			return (
				<p className="mt-2 ml-4" key={employee.id}>
					{employee.firstName.toUpperCase()}{" "}
					<u
						onClick={() => removeEmployeeFromReportList(employee.id)}
						className="text-danger remove-link"
					>
						Remove
					</u>
				</p>
			)
		})

	const commissionFormat = switchToOldFormat ? "New" : "Old"

	return (
		<div className="">
			<h4 className="text-center mt-3 mb-3">
				{view} {view === "Commissions" && "/ Sales"}
				{view === "Commissions" && (
					<button
						onClick={() => setSwitchToOldFormat(!switchToOldFormat)}
						className={`ml-4 btn ${
							switchToOldFormat ? "btn-info" : "btn-dark"
						} `}
					>
						Switch to the {commissionFormat} format
					</button>
				)}
				<button
					onClick={() =>
						toggleView(
							view === "Commissions" ? "Projected Sales" : "Commissions"
						)
					}
					className={`ml-4 btn btn-primary `}
				>
					Toggle View
				</button>
			</h4>
			<hr />
			{view === "Commissions" ? (
				switchToOldFormat ? (
					<LegacyCommissionReport />
				) : (
					<>
						<div className="special-commission-buttons-wrapper">
							{(reportReadyDaycare ||
								reportReadyGroomer1 ||
								reportReadyGroomer2 ||
								reportReadyGroomer3 ||
								reportReadyGroomer4 ||
								reportReadyGroomer5 ||
								reportReadyGroomer6 ||
								reportReadyGroomer7 ||
								reportReadyGroomer8 ||
								reportReadyGroomer9) && (
								<button
									className="text-white rounded-sm px-2 mr-4 bg-red-500"
									onClick={resetReport}
								>
									Reset Report
								</button>
							)}
							<div className="border px-1">
								<DatePicker
									selected={datePickerStart}
									onChange={(date) => setDatePickerStart(date)}
									dateFormat="yyyy-MM-dd"
									placeholderText="From"
									className="mr-2 "
								/>
							</div>

							<div className="border px-1 lg:ml-1">
								<DatePicker
									selected={datePickerEnd}
									onChange={(date) => setDatePickerEnd(date)}
									dateFormat="yyyy-MM-dd"
									placeholderText="To"
									className=""
								/>
							</div>
							<button
								onClick={() => setEmployeesForReport(allEmployees)}
								className="btn btn-warning btn-calculate-actions"
							>
								SELECT ALL
							</button>
							<button
								onClick={() => setEmployeesForReport([])}
								className="btn btn-danger btn-calculate-actions"
								disabled={!employeesForReport.length}
							>
								REMOVE ALL
							</button>
						</div>
						<div className="flex flex-col md:flex-row justify-center mt-2 md:mt-4 space-y-2 md:space-y-0 md:space-x-2 w-full">
							{employees}
							<button
								onClick={generateReportHandler}
								disabled={!employeesForReport.length}
								className="btn btn-primary generate-report-btn w-full md:w-auto"
							>
								Generate Report
							</button>
						</div>

						<hr />

						<div>
							<div>{reportList}</div>
							<ReportContainer
								totalSalesAllEmployees={totalSalesAllEmployees}
								datePickerStart={dateStart}
								datePickerEnd={dateEnd}
								reportReadyProducts={reportReadyProducts}
								reportReadyDaycare={reportReadyDaycare}
								reportReadyGroomer1={reportReadyGroomer1}
								reportReadyGroomer2={reportReadyGroomer2}
								reportReadyGroomer3={reportReadyGroomer3}
								reportReadyGroomer4={reportReadyGroomer4}
								reportReadyGroomer5={reportReadyGroomer5}
								reportReadyGroomer6={reportReadyGroomer6}
								reportReadyGroomer7={reportReadyGroomer7}
								reportReadyGroomer8={reportReadyGroomer8}
								reportReadyGroomer9={reportReadyGroomer9}
							/>
						</div>
					</>
				)
			) : (
				<div className="special-commission-buttons-wrapper">
					<button
						className="text-white rounded-sm px-2 mr-4 bg-red-500"
						onClick={() => {
							setDatePickerStart("")
							setDatePickerEnd("")
						}}
					>
						Reset Dates
					</button>
					<div className="border px-1">
						<DatePicker
							selected={datePickerStart}
							onChange={(date) => setDatePickerStart(date)}
							dateFormat="yyyy-MM-dd"
							placeholderText="From"
							className="mr-2 "
						/>
					</div>

					<div className="border px-1 lg:ml-1">
						<DatePicker
							selected={datePickerEnd}
							onChange={(date) => setDatePickerEnd(date)}
							dateFormat="yyyy-MM-dd"
							placeholderText="To"
							className=""
						/>
					</div>

					<button
						onClick={() => setEmployeesForReport(allEmployees)}
						className="btn btn-warning btn-calculate-actions"
					>
						SELECT ALL
					</button>
					<button
						onClick={() => setEmployeesForReport([])}
						className="btn btn-danger btn-calculate-actions"
						disabled={!employeesForReport.length}
					>
						REMOVE ALL
					</button>

					<div className="flex flex-col md:flex-row justify-center mt-2 md:mt-4 space-y-2 md:space-y-0 md:space-x-2 w-full">
						{employees}
						<button
							onClick={calculateSalesHandler}
							disabled={!employeesForReport.length}
							className="btn btn-primary generate-report-btn w-full md:w-auto"
						>
							Calculate Sales
						</button>
					</div>

					<div>{reportList}</div>
					<div className="mt-4">
						<h3 className="text-center">
							Approximate Total Sales Amount: <br />$
							{totalProjectedSales && totalProjectedSales.toFixed(2)}
						</h3>
					</div>
				</div>
			)}
		</div>
	)
}

export default SpecialCalculations
